import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 14.375rem 11.125vw 9.375rem;

  @media (max-width: 1199px) {
    margin: 12.8125rem 1.5625rem 5rem;
  }

  @media (max-width: 768px) {
    margin-top: 9.375rem;
  }
`

const Wrapper = styled.div`
  max-width: 77.75rem;
  margin: 0 auto;
`

const Title = styled.h1`
  font-weight: 400;
  font-size: 3vw;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;
  text-transform: uppercase;
  position: relative;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const Description = styled.div`
  margin-top: 5rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;

  a {
    color: ${({ theme }) => theme.colors.variants.primaryDark};
    text-decoration: underline;
  }

  ul {
    list-style: inside;

    li {
      &::marker {
        font-size: 1rem;
      }
    }
  }
`
